import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withSnackbar } from 'notistack';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';

import * as Helper from '../util/Helper.js';
import Button from '../inputs/Button';
import {
  addToDataBase,
  deleteFromDataBase,
  updateDataBase,
} from '../redux/actions/dataBase';
import TextField from '../inputs/Text';
import CustomRadio from '../inputs/CustomRadio';

const styles = theme => ({
  dialogPaper: {
    position: 'relative',
    borderRadius: 0,
    width: 432,
    backgroundColor: theme.modeColors.inputBackground,
  },
  tooltip: {
    fontSize: '0.875rem',
    padding: '8px 12px',
  },
  container: {
    width: '100%',
  },
  hamburgerIcon: {
    fill: '#ccc',
    '&:hover': {
      cursor: 'move',
    },
  },
  sortableHelper: {
    zIndex: '10000',
    listStyle: 'none !important',
    boxShadow: '0px 2px 5px -2px rgba(0,0,0,0.75)',
    backgroundColor: '#fff',
  },
  deleteIcon: {
    right: '0.5rem',
  },
});

const ManageClasses = ({
  close,
  isOpen,
  classes,
  PublicClasses,
  proposal,
  proposalId,
  proposalClass,
  addToDataBase,
  enqueueSnackbar,
  selectedPhase,
  deleteFromDataBase,
  proposalsData,
  updateDataBase,
}) => {
  const [chosenColor, setChosenColor] = useState('');
  const [nameOfClass, setNameOfClass] = useState('');
  const [openRemove, setOpenRemove] = useState(false);
  const [classData, setClassData] = useState({});
  const [proposalClasses, setProposalClasses] = useState([]);
  const proposalClassColors = [
    '#999da0',
    '#61be65',
    '#f3e200',
    '#ffa632',
    '#ff4c4c',
    '#3f51b5',
    '#61a8be',
    '#bc61be',
    '#dedede',
    '#a74c32',
    '#b6a55c',
    '#ffa8ad',
  ];

  useEffect(() => {
    if (PublicClasses) {
      const cloneArray = () => {
        const copiedPublicClasses = _.map(PublicClasses, _.clone);
        const sortedCopyData = copiedPublicClasses
          .filter(element => element !== null)
          .sort((a, b) => a.position - b.position);
        setProposalClasses(sortedCopyData);
      };
      cloneArray();
    }
  }, [PublicClasses]);

  const handleChange = event => {
    const { value, name } = event.target;

    if (name === 'nameOfClass') {
      setNameOfClass(value);
    } else if (name === 'chosenColor') {
      setChosenColor(value);
    }
  };

  const createClass = (
    chosenColor,
    nameOfClass,
    position,
    addToDataBase,
    enqueueSnackbar
  ) => {
    Helper.createClass(
      chosenColor,
      nameOfClass,
      position,
      addToDataBase,
      enqueueSnackbar
    );

    if (chosenColor && nameOfClass) {
      resetValues();
    }
  };

  const resetValues = () => {
    setChosenColor('');
    setNameOfClass('');
  };

  const handleCloseRemove = () => setOpenRemove(false);

  const handleOpenRemove = publicClass => {
    setOpenRemove(true);
    setClassData(publicClass);
  };

  const removeClass = () => {
    const { id } = classData;

    Helper.removeClass(
      id,
      enqueueSnackbar,
      deleteFromDataBase,
      addToDataBase,
      proposalsData
    );
    handleCloseRemove();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedClasses = arrayMove(proposalClasses, oldIndex, newIndex);
    const updatedClassesPosition = _.map(sortedClasses, (value, index) => ({
      ...value,
      position: index + 1,
    }));
    setProposalClasses(updatedClassesPosition);
  };

  const updateDatabaseHandler = () => {
    proposalClasses.forEach(proposalClass => {
      updateDataBase('PublicClasses', proposalClass.id, proposalClass);
    });
    close();
  };

  const DragHandle = sortableHandle(() => (
    <MenuIcon classes={{ root: classes.hamburgerIcon }} />
  ));

  const SortableItem = sortableElement(({ value }) => (
    <div className="d-flex align-items-center pl-1">
      <DragHandle />
      <ListItem
        onClick={() =>
          Helper.addClassToProposal(
            value,
            proposalId,
            addToDataBase,
            enqueueSnackbar,
            selectedPhase
          )
        }
        key={value.id}
        className="d-flex align-items-center justify-content-between pl-1"
        button
        classes={{ container: classes.container }}
      >
        <div className="big-class-container" style={{ backgroundColor: value.colour }}>
          {value.class}
        </div>
        <ListItemSecondaryAction classes={{ root: classes.deleteIcon }}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Remove the class from every where"
          >
            <IconButton
              onClick={() => handleOpenRemove(value)}
              size="small"
              aria-label="Delete"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  ));

  const SortableContainer = sortableContainer(({ items }) => (
    <List>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} />
      ))}
    </List>
  ));

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <div className="px-3 pt-3">
        <h2>Manage classes </h2>
        <div className="border-bottom d-flex align-items-center pb-2">
          <h3 className="mb-0 pr-1 py-1">Proposal {proposal.number}:</h3>
          {proposalClass && (
            <div
              className="big-class-container "
              style={{ backgroundColor: proposalClass.colour }}
            >
              {proposalClass.class}
              <IconButton
                size="small"
                aria-label="Delete"
                color="inherit"
                className="delete-class"
                onClick={() => Helper.removeClassFromProposal(proposalId, addToDataBase)}
              >
                <CloseIcon fontSize="small" color="inherit" />
              </IconButton>
            </div>
          )}
        </div>

        <div className="pt-2 border-bottom">
          <h5 className="mb-0">Choose class for this proposal</h5>
          {proposalClasses ? (
            <SortableContainer
              items={proposalClasses}
              onSortEnd={onSortEnd}
              useDragHandle
              helperClass={classes.sortableHelper}
            />
          ) : null}
        </div>

        <div className="py-3">
          <h5 className="mb-2">Create a new class</h5>
          <div>
            <TextField
              value={nameOfClass}
              onChange={handleChange}
              name="nameOfClass"
              placeholder="Text"
              size="md"
            />
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div>
                <div className="sm mb-1">Choose colour</div>
                {proposalClassColors.map((color, i) => (
                  <CustomRadio
                    checked={color === chosenColor}
                    onChange={handleChange}
                    key={i}
                    name="chosenColor"
                    color={color}
                    value={color}
                  />
                ))}
              </div>

              <Button
                style={{ padding: '9px 12px 7px' }}
                size="md"
                variant="outlined"
                color="primary"
                onClick={() => {
                  const position = proposalClasses.length + 1;
                  createClass(
                    chosenColor,
                    nameOfClass,
                    position,
                    addToDataBase,
                    enqueueSnackbar
                  );
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end pt-3 position-fixed">
          <Button
            style={{ marginLeft: '16px' }}
            size="md"
            variant="contained"
            onClick={updateDatabaseHandler}
            color="primary"
          >
            Update class position
          </Button>
          <Button
            style={{ marginLeft: '16px' }}
            size="md"
            variant="contained"
            onClick={close}
            color="primary"
          >
            Done
          </Button>
        </div>
      </div>
      <RemoveClass
        open={openRemove}
        handleClose={handleCloseRemove}
        removeClass={removeClass}
        className={classData.class}
      />
    </Dialog>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
    updateDataBase: (coll, doc, data) => dispatch(updateDataBase(coll, doc, data)),
  };
};

const mapStateToProps = state => {
  return {
    PublicClasses: state.firestore.data.PublicClasses,
    proposalsData: state.firestore.data.proposals,
    selectedPhase: state.proposals.selectedPhase,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'PublicClasses' }])
)(withSnackbar(withStyles(styles)(ManageClasses)));

const RemoveClass = ({ open, handleClose, removeClass, className }) => (
  <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Remove class.</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        That's will remove {className} from all proposals.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button size="md" variant="outlined" color="primary" onClick={() => handleClose()}>
        Cancel
      </Button>
      <Button
        size="md"
        variant="contained"
        color="primary"
        onClick={() => removeClass()}
        autoFocus
      >
        remove
      </Button>
    </DialogActions>
  </Dialog>
);
