import React from 'react';
import VimeoPlayer from 'react-player/lib/players/Vimeo';
import styled from 'styled-components';
import moment from 'moment';

const setWidthByZoomLevel = size => {
  const data = {
    1: '5.5rem',
    2: '9.125rem',
    3: '11.5rem',
    4: '17.375rem',
    5: '28rem',
    6: '45rem',
  };

  return data[size] || null;
};

const getVideoId = proposalNumber => {
  const videoIds = {
    5: '396182964/0119c576bd',
    7: '396183106/bd0aeb2879',
    23: '396183273/8d5ab0153d',
    37: '396183467/4fb789172b',
    44: '396184888/694bf7a012',
    48: '396186841/0f034bec74',
  };

  return videoIds[proposalNumber] || null;
};

const Container = styled.div`
  position: relative;
  border-radius: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: ${({ size }) => (size ? setWidthByZoomLevel(size) : undefined)};
  height: auto;
  background-color: ${({ theme }) => theme.modeColors.cardBackground};
`;

const MediaContent = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.5%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
`;

const Video = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ClassTagWithText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.25rem 0.75rem 0.125rem;
  color: #fff;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#ffa632')};
  z-index: 2;
`;

const ClassTagWithoutText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#ffa632')};
  z-index: 2;
  width: 0.625rem;
  height: 100%;
`;

const LastSeenTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.75rem 0.125rem;
  color: #fff;
  background-color: #999da0;
  z-index: 2;
`;

const VideoCard = ({ seen, proposal, proposalClass, size }) => {
  const readOnly = process.env.REACT_APP_READ_ONLY;
  const videoId = getVideoId(proposal.number);

  return (
    videoId && (
      <Container size={size}>
        {size > 1 && (
          <MediaContent>
            <Video>
              <VimeoPlayer
                url={`https://vimeo.com/${videoId}`}
                width="100%"
                height="100%"
                controls
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                light={true}
              />
            </Video>
          </MediaContent>
        )}

        <div className="py-1 px-2">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0">{proposal.number}</h2>
            {size > 3 && <h2 className="mb-0 sm">{proposal.name}</h2>}
          </div>
        </div>

        {proposalClass && size > 1 && (
          <ClassTagWithText bgColor={proposalClass.colour}>
            {proposalClass.class}
          </ClassTagWithText>
        )}
        {proposalClass && size === 1 && (
          <ClassTagWithoutText bgColor={proposalClass.colour} />
        )}

        {size > 3 && (
          <LastSeenTag>
            {seen && !readOnly
              ? `Last seen ${moment(new Date(seen)).format('D.MM.YYYY')}`
              : readOnly
              ? ''
              : 'Unseen'}
          </LastSeenTag>
        )}
      </Container>
    )
  );
};

export default VideoCard;
