import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { filterProposals } from '../../redux/actions/proposalsActions';
import NestedMultipleSelect from '../../inputs/NestedMultipleSelect';
import * as searchHelper from '../../util/searchHelper';

const FilterElement = ({ filterProposals, history, proposals }) => {
  const [values, setValues] = useState([]);

  const handleChange = event => {};

  useEffect(() => {
    const { search } = history.location;
    if (!search) setValues([]);
  }, [history.location]);

  useEffect(() => {
    const { search } = history.location;
    const searchQuery = searchHelper.getSearchQuery(search);

    if (searchQuery.advanced_search) {
      const searchKeys = searchQuery.advanced_search.split(',');
      setValues(searchKeys);
    }
  }, [history.location]);

  const selectAll = elements => {
    const newValues = [].concat.apply(
      [],
      _.map(elements, (val, key) => val)
    );
    setValues(newValues);
  };

  const clearAll = () => {
    setValues([]);
  };

  const checker = (arr, target) => target.every(v => arr.includes(v));

  const clickGroupOptions = (group, elements) => {
    const selected = checker(values, elements[group]);

    if (!selected) {
      const newValues = _.uniq([...values, ...elements[group]]);
      setValues(newValues);
    } else {
      const newValues = values.filter(el => elements[group].indexOf(el) < 0);
      setValues(newValues);
    }
  };

  const clickOption = option => {
    if (values.includes(option)) {
      const newValues = values.filter(val => val !== option);
      setValues(newValues);
    } else {
      const newValues = values.concat(option);
      setValues(newValues);
    }
  };

  const getElements = proposals => {
    // Get proposals keys
    const proposalKeys = Object.keys(proposals);
    const filtered = {};

    proposalKeys.forEach(
      key =>
        (filtered[key] = Object.values(proposals[key].elements).map(
          element => element.name
        ))
    );

    return filtered;
  };

  const applyFilters = () => {
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'advanced_search',
        values
      ),
    });

    filterProposals(history.location.search);
  };

  const renderNestedMultipleSelect = () => {
    if (!_.isEmpty(proposals)) {
      const elements = getElements(proposals);

      return (
        <div id="filter-advanced">
          <NestedMultipleSelect
            options={elements}
            values={values}
            onChange={handleChange}
            selectAll={() => selectAll(elements)}
            clearAll={clearAll}
            placeholder="Filter by element"
            clickGroupOptions={group => clickGroupOptions(group, elements)}
            clickOption={clickOption}
            apply={applyFilters}
          />
        </div>
      );
    }
    return null;
  };

  return renderNestedMultipleSelect();
};

const mapStateToProps = state => {
  return {
    proposals: state.proposals.data,
  };
};

FilterElement.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
  proposals: PropTypes.objectOf(Object).isRequired,
  filterProposals: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, { filterProposals })(FilterElement));
